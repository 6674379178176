import React, { useState, useEffect } from "react";
import { Accordion } from "react-bootstrap";
import "bulma/css/bulma.min.css";

function App() {
  const [inputSearchTerm, setInputSearchTerm] = useState("");
  const [searchTerm, setSearchTerm] = useState("");
  const [data, setSearchData] = useState([]);
  const [groupedResults, setGroupedResults] = useState({});
  const [searchResults, setSearchResults] = useState([]);
  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    setIsLoading(true);

    fetch(
      "https://newsinghmedicalagencies.s3.ap-south-1.amazonaws.com/march-2023.json"
    )
      .then((response) => response.json())
      .then((data) => {
        setSearchData(data);
        setIsLoading(false);
      })
      .catch((error) => {
        console.error("Error fetching data:", error);
        setIsLoading(false);
      });
  }, []);

  useEffect(() => {
    const groupedResults = data.reduce((acc, item) => {
      const company = item.Company;
      if (!acc[company]) {
        acc[company] = [];
      }
      acc[company].push(item);
      return acc;
    }, {});

    setGroupedResults(groupedResults);
  }, [data]);

  function handleChange(event) {
    setInputSearchTerm(event.target.value);

    const term = event.target.value.toLowerCase().trim().replace(/\s/g, "");

    setSearchTerm(term);

    console.log("term", term);

    if (term.length === 0) {
      setSearchResults([]);
      return;
    }

    let count = 0;
    const filteredResults = data.filter((item) => {
      const company = item.Company.toLowerCase().replace(/\s/g, "");
      const product = item.Item.toLowerCase().replace(/\s/g, "");
      const pack = item.Pack.toLowerCase().replace(/\s/g, "");

      if (
        company.includes(term) ||
        product.includes(term) ||
        pack.includes(term)
      ) {
        count++;
        return count <= 50;
      }
      return false;
    });

    setSearchResults(filteredResults);
  }

  return (
    <div className="container">
      <section className="hero is-medium is-black">
        <div className="hero-body">
          <div className="container has-text-centered">
            <h1 className="title is-size-1 is-mobile">
              New Singh Medical Agencies
            </h1>
            <br></br>
            <h3 className="subtitle">
              678, Mattaur Rd, Sector 70, Sahibzada Ajit Singh Nagar
            </h3>
          </div>
        </div>
      </section>

      <h4 className="subtitle"style={{color:"white"}}  >Search Products</h4>
      <div className="field">
        <div className="control">
          <input
            className="input is-rounded"
            type="text"
            placeholder="Search..."
            value={inputSearchTerm}
            onChange={handleChange}
          />
        </div>
      </div>
      {/* only show the table when there are search results */}
      {searchResults.length > 0 && searchTerm.length > 0 && (
        <table className="table is-fullwidth">
          <thead>
            <tr>
              <th>Company</th>
              <th>Item</th>
              <th>Pack</th>
            </tr>
          </thead>
          <tbody>
            {searchResults.map((item, index) => (
              <tr key={index}>
                <td>{item.Company}</td>
                <td>{item.Item}</td>
                <td>{item.Pack}</td>
              </tr>
            ))}
          </tbody>
        </table>
      )}
      {/* show loading message when data is being fetched */}
      {isLoading && <p>Loading...</p>}
      {/* show no results message when there are no search results */}
      {!isLoading && searchResults.length === 0 && searchTerm.length > 0 && (
        <p>No search results found.</p>
      )}

      <h4 className="subtitle" style={{color:"white"}} >List of companies</h4>
      
      <div className = "container">
      <Accordion defaultActiveKey="0">
        {Object.keys(groupedResults).map((company, index) => (
          <Accordion.Item eventKey={index}>
            <Accordion.Header>{company}</Accordion.Header>
            <Accordion.Body>
              <div class="row justify-content-center">
                <div class="col-auto">
                  <table class="table table-striped">
                    <thead>
                      <tr>
                        <th>Item</th>
                        <th>Pack</th>
                      </tr>
                    </thead>
                    <tbody>
                      {groupedResults[company].map((item, index) => (
                        <tr key={index}>
                          <td>{item.Item}</td>
                          <td>{item.Pack}</td>
                        </tr>
                      ))}
                    </tbody>
                  </table>
                </div>
              </div>
            </Accordion.Body>
          </Accordion.Item>
        ))}
        </Accordion>
      </div>
    </div>
  );
}

export default App;
